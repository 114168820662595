define("js2-admin/router", ["exports", "js2-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    goBack: Ember.inject.service(),
    didTransition: function didTransition() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      Ember.run.scheduleOnce('afterRender', this, this.sendMetric); // this.goBack.trackOrigin(infos, this.targetState.routerJs.state.queryParams)
    },
    sendMetric: function sendMetric() {
      var metrics = this.metrics;
      var page = this.currentRouteName === undefined ? 'unknown' : this.currentRouteName;
      metrics.trackPage({
        page: page
      });
    }
  });
  /* eslint-disable-next-line array-callback-return */

  Router.map(function () {
    this.route('matters', {
      restNamespace: true
    });
    this.route('non-jury-matters', {
      restNamespace: true
    });
    this.route('matters-alt', {
      restNamespace: true
    });
    this.route('matter', {
      restNamespace: true,
      path: 'matters/:matter_id'
    }, function () {
      this.route('dashboard');
      this.route('updates');
      this.route('analytics');
      this.route('detail');
      this.route('report-wizard');
      this.route('workflows', function () {
        this.route('list');
        this.route('edit', {
          path: 'edit/:subject_id'
        });
      });
      this.route('subjects', function () {
        this.route('list', {
          path: '/'
        });
        this.route('list');
        this.route('info', {
          path: 'info/:subject_id'
        }, function () {
          this.route('documents');
          this.route('notes');
          this.route('workflow');
          this.route('social-links');
          this.route('analytics');
          this.route('posts');
          this.route('db-memos', function () {
            this.route('find-db');
          });
        });
        this.route('import');
        this.route('inf', function () {
          this.route('timeline-events');
        });
      });
      this.route('documents', function () {
        this.route('info', {
          path: 'info/:document_id'
        });
        this.route('edit', {
          path: 'edit/:document_id'
        });
        this.route('search');
        this.route('list-search');
        this.route('keywords-list-search');
      });
      this.route('info', function () {
        this.route('notes');
        this.route('report-components');
        this.route('quick-links');
      });
      this.route('summary');
      this.route('renumbering-wizard');
      this.route('subject-tags');
      this.route('subject', function () {
        this.route('info', function () {
          this.route('analytics');
        });
      });
      this.route('monitoring', function () {
        this.route('list');
      });
      this.route('db-memos', function () {
        this.route('find-db');
        this.route('browse-memos');
        this.route('for-db', {
          path: '/for-db/:db_id'
        });
      });
    });
    this.route('subjects');
    this.route('auth', function () {
      this.route('login');
      this.route('logout');
    });
    this.route('preview', {
      path: 'preview/:document_id'
    }, function () {
      this.route('timeline-events', {
        path: 'timeline-events/:subject_id'
      });
    });
    this.route('unauthorized');
    this.route('analytics');
    this.route('search-documents');
    this.route('smm');
    this.route('trial-data');
    this.route('me', function () {
      this.route('notifications');
    });
    this.route('document-url-upgrade');
    this.route('manage-subject-tags'); // Catch routes not found

    this.route('403', {
      path: '*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});